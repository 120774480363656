<template>
  <div class="content_area wrap">
    <commonPageBanner :url="backUrl" />
    <p class="title">关于征集2020—2021年版《会员名录》企业信息的通知</p>
    <div style="margin-top: 50px">各会员单位：</div>
    <div class="textd">
      2020—2021年版《会员名录》即将编印，为了能准确反映会员单位信息，帮助会员单位做好企业宣传，便于货主和相关企业与会员单位的业务联系，现将本通知发给你们。望接通知后，请各会员单位核查2019—2020年版《会员名录》中本企业的信息，并将需要变更的相关信息情况，于11月30日前以电子邮件方式告知协会秘书处，以便及时更正，邮件主题：名录变更+企业简称。邮箱地址：zhougang@siffa.org。（友情提醒：近期部分会员单位电子邮件地址、企业网站的更新、变动情况较大，如果以前未在《会员名录》上登载的本次亦请提供，以便于企业的商务推广）
    </div>
    <div class="textd">
      同时，2020—2021年版《会员名录》的封皮可做企业彩色广告。会员单位收费从优，如需了解详细情况，可来电或发邮件与协会秘书处联系，联系电话：6560
      0859，联系邮箱：zhougang@siffa.org。联系人：周老师。
    </div>
    <div class="textd">谢谢各会员单位的支持与配合。</div>
    <div class="p100 mt40">青岛市国际物流商会</div>
    <div class="p100">2020年11月</div>
  </div>
</template>


<script>
import commonPageBanner from "~qlc/components/layouts/pageBanner";
export default {
  components: {
    commonPageBanner,
  },
  data() {
    return {
      backUrl:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png",
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  font-size: 14px;
  line-height: 25px;
  p,
  div {
    word-break: break-all;
    word-wrap: break-word;
    break-word: break-all;
  }
}
.title {
  text-align: center;
  color: #ff0000;
  font-size: 24px;
}
.textd {
  text-indent: 2em;
}
.p100 {
  padding-right: 200px;
  text-align: right;
}
.mt40 {
  margin-top: 40px;
}
</style>
